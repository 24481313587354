import React, { useRef, useState } from 'react'

import Helmet from 'react-helmet'
import ReCAPTCHA from 'react-google-recaptcha'
import { DateTime, Settings } from 'luxon'

import emailValidator from '../helpers/emailValidator'

import breakfast from '../images/breakfast.png'
import sausage from '../images/sausage.png'
import dogs from '../images/dogs.png'
import breakfastMobile from '../images/breakfast-mobile.png'
import dogsMobile from '../images/dogs-mobile.png'

import bags from '../images/bags.png'
import favoritesText from '../images/favorites-text.png'
import heroLogo from '../images/hero-logo.png'

import smithfieldWhite from '../images/smithfield-white.png'
import eckrich from '../images/eckrich.png'
import nathans from '../images/nathans.png'
import farmland from '../images/farmland.png'
import armour from '../images/armour.png'
import margherita from '../images/margherita.png'

import rulesPdf from '../assets/rules.pdf'

const PUBLIC_RECAPTCHA_KEY = '6LfcMq8cAAAAAFWOG-xUcwLoKR0D0x-2LHsHJqf8'

const FUNCTIONS_API_BASE_URL = 'https://yearofsmithfield.com/.netlify/functions'

Settings.defaultZone = 'America/New_York';
const END_DATE = DateTime.fromObject({ year: 2022, month: 10, day: 31, hour: 23, minute: 59, second: 59 });

const IndexPage = () => {
  const recaptchaValue = useRef()

  const [ closeSweepstakes, setCloseSweepstakes ] = useState(DateTime.now() > END_DATE)
  const [ formSubmitted, setFormSubmitted ] = useState(false)
  const [ formValidation, setFormValidation ] = useState({})
  const [ emailUsedError, setEmailUsedError ] = useState(false)
  const [ formValues, setFormValues ] = useState({})
  const [ isLoading, setIsLoading ] = useState(false)
  const [ errorOccurred, setErrorOccurred ] = useState(false)

  const urlEncodeForm = (formData) => {
    return Object.keys(formData)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(formData[key]))
      .join("&")
  }

  const handleTextInputChange = (e) => {
    setFormValues((previousState) => ({ ...previousState, [e.target.name]: e.target.value }))
  }

  const handleCheckboxChange = (e) => {
    setFormValues((previousState) => ({ ...previousState, [e.target.name]: e.target.checked }))
  }

  const handleRecaptchaChange = (newValue) => {
    recaptchaValue.current = newValue
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (DateTime.now() > END_DATE) {
      setErrorOccurred(true);
      return;
    }

    if (isLoading) {
      return
    }

    // Show validation error for field if value is truthy
    const validation = {
      name: formValues.name === undefined || formValues.name.trim() === '',
      email: formValues.email === undefined || formValues.email.trim() === '',
      emailInvalid: !emailValidator(formValues.email),
      agree1: !formValues.agree1,
      agree2: !formValues.agree2,
      captcha: recaptchaValue.current === undefined
    }

    setFormValidation(validation)

    if (Object.values(validation).some(item => item === true)) {
      console.error('Missing field! Aborting')
      return
    }

    try {
      setIsLoading(true)

      const emailSubmittedResponse = await fetch(`${FUNCTIONS_API_BASE_URL}/emailSubmitted?email=${formValues.email}`)
      const emailSubmitted = await emailSubmittedResponse.json()
  
      if (emailSubmitted.emailFound) {
        console.error('Email already submitted! Aborting.')

        setEmailUsedError(true)

        return
      }

      formValues.email = formValues.email.toLowerCase()
  
      const response = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: urlEncodeForm({
          'form-name': 'registrationForm',
          'g-recaptcha-response': recaptchaValue.current,
          ...formValues
        })
      })

      setErrorOccurred(false)
      setFormSubmitted(true)
    } catch (error) {
      console.error(error)
      setErrorOccurred(true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>

    <Helmet>
      <link rel='preconnect' href='https://fonts.googleapis.com' />
      <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
      <link href='https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap' rel='stylesheet' />
      <link href="https://fonts.googleapis.com/css2?family=Oswald&display=swap" rel="stylesheet" />
    </Helmet>

    <main className='background oswald-semibold oswald-blue flex flex-col'>

      { /* Mobile floating images */ }
      <div className='block md:hidden'>
        <img className='breakfast' src={breakfastMobile}></img>
        <img className='dogs' src={dogsMobile}></img>
      </div>

      { /* Desktop floating images */ }
      <div className='hero-images-container hidden md:block'>
        <img className='breakfast' src={breakfast}></img>
        <img className='salt' src={sausage}></img>
        <img className='dogs' src={dogs}></img>
      </div>

      <div className='flex flex-col mt-8 md:flex-row md:justify-center lg:mt-16'>
        <img className='w-24 mx-auto mt-7 md:w-32 md:mx-2 md:mt-8' src={heroLogo}></img>
        <div className='flex flex-col mt-2 text-center md:mt-6 md:mx-2'>
          <div className='text-2xl'>YOU COULD</div>
          <div className='text-6xl'>WIN FREE</div>
          <div className='mt-1 text-2xl leading-6'>GROCERIES FOR A YEAR<br /> WITH SMITHFIELD FOODS</div>
        </div>
      </div>

      { !formSubmitted && !closeSweepstakes &&
      <form data-netlify='true' data-netlify-recaptcha='true' data-netlify-honeypot='bot-field' name='registrationForm' method='post' onSubmit={handleSubmit} className='flex flex-col mt-8'>
        <input type='hidden' name='form-name' value='registrationForm' />
        <p hidden>
          <label>
            Don't fill this out: <input name='bot-field' onChange={handleTextInputChange} />
          </label>
        </p>
        <div className='flex flex-col align-middle w-64 mx-auto'>
          <input className='mt-4' type='text' name='name' placeholder='NAME' onChange={handleTextInputChange} />
          { formValidation.name &&
            <div className='text-red-600 oswald text-sm leading-5 mt-1 text-left'>This field is required. Please complete it.</div>
          }
          <input className='mt-4' type='text' name='email' placeholder='EMAIL' onChange={handleTextInputChange} />
          { formValidation.email &&
            <div className='text-red-600 oswald text-sm leading-5 mt-1 text-left'>This field is required. Please complete it.</div>
          }
          { !formValidation.email && formValidation.emailInvalid &&
            <div className='text-red-600 oswald text-sm leading-5 mt-1 text-left'>Please enter a valid email.</div>
          }
        </div>
        <div className='w-80 mx-auto px-2 mt-6 md:w-96'>
          <label className='container'>
            <span className='block oswald text-sm leading-4'>
              By entering sweepstakes, you agree to our <a href="https://smithfield.smithfieldfoods.com/en-us/privacy-policy/" target="_blank">Privacy Policy</a> and <a href="https://smithfield.smithfieldfoods.com/en-us/terms-of-use/" target='blank'>Terms & Conditions</a>.
            </span>
            { formValidation.agree1 &&
              <div className='text-red-600 oswald text-sm leading-5 mt-1 text-left'>This field is required. Please complete it.</div>
            }
            <input name='agree1' type='checkbox' onChange={handleCheckboxChange} />
            <span className='checkmark'></span>
          </label>

          <label className='container'>
            <span className='block oswald text-sm leading-4'>
            Please provide me with news, product updates, coupons, promotions, contests and other promotional information for the Smithfield Foods family of brands.
            </span>
            { formValidation.agree2 &&
              <div className='text-red-600 oswald text-sm leading-5 mt-1 text-left'>This field is required. Please complete it.</div>
            }
            <input name='agree2' type='checkbox' onChange={handleCheckboxChange} />
            <span className='checkmark'></span>
          </label>
          <ReCAPTCHA className='flex justify-center' sitekey={`${PUBLIC_RECAPTCHA_KEY}`} onChange={handleRecaptchaChange} />
          { formValidation.captcha &&
            <div className='text-red-600 oswald text-sm leading-5 mt-1 text-center'>Please complete the captcha.</div>
          }
          <div className='flex justify-center'>
            <input className={`bg-smithfield-blue w-36 mt-4 mx-auto ${ isLoading ? 'opacity-50' : 'opacity-100 hover:opacity-90 cursor-pointer'}`} disabled={isLoading} type='submit' value='ENTER NOW' />
          </div>
          { emailUsedError && 
            <div className='text-red-600 oswald text-sm leading-5 mt-1 text-center'>Sorry, that email was already submitted.</div>
          }
          { !emailUsedError && errorOccurred &&
            <div className='text-red-600 oswald text-sm leading-5 mt-1 text-center'>An error occurred. Please try again later.</div>
          }
        </div>
      </form>
      }

      { formSubmitted &&
        <div className='mt-12 text-4xl text-center md:my-24'>
          Thank you for your entry
        </div>
      }

      { closeSweepstakes &&
        <div className='mt-8 text-4xl text-center md:my-24'>
          This sweepstakes<br />is closed.
        </div>
      }

      <div className='flex flex-col mx-auto mt-8 md:flex-row md:justify-center md:w-10/12'>
        <div className='w-80 px-4 md:order-2'>
          <div className='mt-6 text-4xl text-center md:mt-4 md:text-left'>
            Grand Prize
          </div>
          <div className='oswald text-lg leading-5 mt-1 text-center md:text-left'>
            One lucky winner will win a year of free Smithfield Foods groceries valued at $5,000.
          </div>
          <div className='mt-4 text-4xl text-center md:text-left'>
            Second Prize
          </div>
          <div className='oswald text-lg leading-5 mt-1 text-center md:text-left'>
            Two secondary winners will take home a month  of free Smithfield Foods groceries valued at $600.
          </div>
        </div>
        <div className='px-4 md:order-1'>
          <img className='w-64 mt-8 mx-auto md:mt-4 md:mx-0' src={bags}></img>
        </div>
      </div>

      <div className='favorites text-center mt-8 py-12 bg-cover md:py-32 2xl:bg-cover 2xl:bg-no-repeat'>
        <div>FILL UP ON ALL YOUR</div>
        <img className='my-2 mx-auto' src={favoritesText}></img>
        <div>AT WALMART</div>
        <a href='https://www.walmart.com/m/brands/smithfieldfoodsIncTest' target='_blank'><button className='bg-smithfield-blue w-36 mt-4 hover:opacity-90'>SHOP NOW</button></a>
      </div>

      <div className='parade py-8'>
        <div className='flex flex-col max-w-6xl md:flex-row md:justify-center md:mx-auto'>
          <div className='flex justify-center items-center mb-4 md:mb-0'>
            <img className='w-24 h-6 mx-4 md:h-7 md:w-32 ' src={smithfieldWhite}></img>
            <img className='w-16 mx-4 md:w-24 ' src={eckrich}></img>
            <img className='w-16 mx-4 md:w-24 ' src={nathans}></img>
          </div>
          <div className='flex justify-center items-center'>
            <img className='w-20 mx-4  md:w-24 md:mx-4' src={armour}></img>
            <img className='w-16 mx-4  md:w-24 md:mx-4' src={farmland}></img>
            <img className='w-16 mx-4  md:w-24 md:mx-4' src={margherita}></img>
          </div>
        </div>
      </div>

    </main>

    <footer className='oswald px-8 py-12 md:px-32'>
      <div className='max-w-4xl mx-auto'>
        <b>NO PURCHASE OR PAYMENT NECESSARY TO ENTER OR WIN.</b> Sweepstakes begins 08/15/22 at 12:00:01 AM ET and ends 10/31/22 at 11:59:59 PM ET. Odds of winning will be determined by the number of eligible entries received. 
        Subject to <a href={rulesPdf} target='_blank'>Official Rules</a>*. Void where prohibited. Walmart Stores, Inc. is not responsible for the conduct, administration or execution of this sweepstakes.
      </div>
    </footer>

    </>
  )
}

export default IndexPage
